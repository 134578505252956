<template>
  <a-radio-group v-model="data.edit_jump_type" @change="handlerChange">
    <div>
      <a-radio style="width:485px" :style="radioStyle" :value="0">无跳转</a-radio>
    </div>
    <div class="flex" v-if="type == '0' || type == '1'">
      <a-radio :style="radioStyle" :value="1">页面链接</a-radio>
      <a-input
        style="width:420px" placeholder="https://xxxx"
        v-if="data.edit_jump_type == 1"
        v-model="data.url"
      />
    </div>
    <div class="flex" v-if="type == '0' || type == '2'">
      <!-- <a-radio :style="radioStyle" :value="2">搜索词条</a-radio> -->
      <a-input
        style="width:420px" placeholder="xxxx"
        v-if="data.edit_jump_type == 2"
        v-model="data.subtitle"
      />
    </div>
    <!-- <div class="flex" v-if="type != '2' && type != '1'">
      <a-radio :style="radioStyle" :value="3">商城商品</a-radio>
      <mall-goods-select :data="data" v-if="data.edit_jump_type == 3" />
    </div>
    <div class="flex" v-if="type == '2'">
      <a-radio :style="radioStyle" :value="2">门店商品</a-radio>
      <shop-goods-select :data="data" v-if="data.edit_jump_type == 2" />
    </div> -->
  </a-radio-group>
</template>

<script>
// import MallGoodsSelect from "./mall-goods-select.vue"
// import ShopGoodsSelect from "./shop-goods-select.vue"

export default {
  // components: { MallGoodsSelect, ShopGoodsSelect },
  props: {
    data: {
      type: Object,
    },
    type: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
    }
  },
  methods: {
    handlerChange(e) {
      if (e.target.value === 0) {
        this.data.edit_jump_data = []
      } else if (e.target.value === 3) {
        if (Array.isArray(this.data.edit_jump_data)) {
          this.data.edit_jump_data = {
            type_id: "",
            goods_id: "",
          }
        }
      } else if (e.target.value == 1) {
        if (Array.isArray(this.data.edit_jump_data)) {
          this.data.edit_jump_data = {
            url: "",
          }
        }
      } else if (e.target.value === 2) {
        if (Array.isArray(this.data.edit_jump_data)) {
          this.data.edit_jump_data = {
            type_id: "",
            son_type_id: "",
            goods_id: "",
          }
          console.log(this.data.edit_jump_data)
        }
      }
    },
  },
}
</script>

<style>
</style>