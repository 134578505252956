<template>
  <div class="p-10">
    <a-form-model layout="horizontal"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 18 }"
    >
      <div id="pc-top-advert-list">
        <div class="home-advert-layout" :class="i==advertList.length-1?'last':''"
          v-for="(item,i) in advertList" :key="item.local_id">
          <a-form-model-item :rules="requiredRule" prop="image_path" label="图片">
            <upload-image type="12" @remove="handlerRemove(i)" :value.sync="item.image_path" />
          </a-form-model-item>
          <div class="home-advert-right-layout">
            <a-form-model-item  :rules="requiredRule" :label="`文案`">
              <a-input style="width:520px;" v-model="item.title" />
            </a-form-model-item>
            <a-form-model-item :rules="requiredRule" prop="edit_jump_data" label="跳转">
              <jump-type :data="item" />
            </a-form-model-item>
          </div>
        </div>
      </div>
    </a-form-model>
    <a-button v-if="advertList.length < 5"
        type="primary"
        @click="handlerAdd"
        style="margin-left:70px;">新增广告图</a-button>
    <a-divider />
    <div style="text-align:center;">
      <!-- <a-button>取消</a-button> -->
      <a-button type="primary" @click="onSubmit" style="margin:0 10px;">保存</a-button>
    </div>
  </div>
</template>

<script>
import UploadImage from "@/components/upload-image"
import JumpType from "../components/jump-type.vue"
import Sortable from 'sortablejs'

import { getPCIndexAdvertList, editTopAdvert } from "@/api/pc/advert"
import { requiredRule } from "@/hooks/use-form-rules"

export default {
  components: { UploadImage, JumpType },
  data() {
    return {
      requiredRule: requiredRule,
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      typeList: [],
      advertList: [],
    }
  },
  mounted() {
    this.initData()
    this.$nextTick(() => {
      this.rowDrop()
    })
  },
  methods: {
    async initData() {
      const { data, code } = await getPCIndexAdvertList()
      if (code === 0) {
        this.advertList = data.list.map(el=>{
          el.local_id = el.id
          el.edit_jump_type = el.url ?  1 : 0
          return el
        })
      }
    },

    onSubmit() {
      let sort = 1
      const params = this.advertList.map(el=>{
        const item = {
          ...el,
          sort: sort++
        }
        delete item.edit_jump_type
        delete item.edit_jump_data
        return item
      })
      editTopAdvert({ data: JSON.stringify(params) }).then((res) => {
        if (res.code === 0) {
          this.$message.success("保存成功！")
        }
      })
    },

    // 新增
    handlerAdd() {
      if (this.advertList.length < 5) {
        this.advertList.push({
          id: 0,
          local_id: Math.round(Math.random()*1000000000),
          sort: 1,
          image_path: "",
          title: "",
          url: ""
        })
      }
    },

    handlerRemove(index) {
      this.advertList.splice(index, 1)
    },

    //行拖拽
    rowDrop () {
      var _this = this
      var $ul = document.getElementById('pc-top-advert-list')
      console.log("$ul", $ul)
      new Sortable($ul, {
        onUpdate: function (event) {
          const item = _this.advertList.splice(event.oldIndex, 1)
          _this.advertList.splice(event.newIndex, 0, item[0])
        },
        animation: 150
      })
    },
  },
}
</script>

<style lang="less">
.home-advert-layout {
  display: flex;
  padding-left: 20px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 40px;
  &.last{
    border-bottom: none;
    margin-bottom: 0;
  }
  .home-advert-right-layout{
    padding-bottom: 0px;
  }
}

.advert-title {
  position: absolute;
  top: 7px;
  left: 60px;
  font-weight: 600;
  font-size: 16px;
}
.border-b-1 {
  position: relative;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 0px;
}
</style>