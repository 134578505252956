import ajax from '@/utils/ajax.js'


/**
 * 获取开屏广告列表
 * @param {*} params 
 * @returns 
 */
 export function getPCIndexAdvertList(params) {
  return ajax.post('/PcAd/getListPosition1',params)
}

/**
 * 编辑开屏广告列表
 * @param {*} params 
 * @returns 
 */
 export function editTopAdvert(params) {
  return ajax.post('/PcAd/editDataPosition1',params)
}

/**
 * 获取首页底部列表
 * @param {*} params 
 * @returns 
 */
 export function getPCBottomAdvertList(params) {
  return ajax.post('/PcAd/getListPosition2',params)
}
/**
 * 编辑首页底部列表
 * @param {*} params 
 * @returns 
 */
 export function editBottmAdvert(params) {
  return ajax.post('/PcAd/editDataPosition2',params)
}

/**
 * 获取首页顶部列表
 * @param {*} params 
 * @returns 
 */
 export function getPCHomeAdvertList(params) {
  return ajax.post('/PcAd/getListPosition3',params)
}
/**
 * 编辑首页顶部列表
 * @param {*} params 
 * @returns 
 */
 export function editHomeAdvert(params) {
  return ajax.post('/PcAd/editDataPosition3',params)
}



/**
 * 获取个人中心推荐位
 * @param {*} params 
 * @returns 
 */
 export function getPCPersonAdvert(params) {
  return ajax.post('/PcAd/getListPosition4',params)
}
/**
 * 编辑个人中心推荐位
 * @param {*} params 
 * @returns 
 */
 export function editPCPersonAdvert(params) {
  return ajax.post('/PcAd/editDataPosition4',params)
}