<template>
  <div class="p-10">
    <a-form-model v-for="(item,i) in advertList"
                  layout="horizontal"
                  :labelCol="{ span: 6 }"
                  :wrapperCol="{ span: 18 }"
                  ref="searchRef"
                  :model="item"
                  :key="i">
      <div class="home-advert-layout"
           :class="i==advertList.length-1?'last':''">
        <a-form-model-item :rules="requiredRule"
                           prop="image_path"
                           :label="`位置${i+1}`">
          <div class="flex">
            <a-cascader v-model="item.value"
                        :options="typeList"
                        style="width:300px"
                        :load-data="loadData"
                        placeholder="请选择展示分类"
                        change-on-select />
            <a-button class="ml-4 p-0" type="link"
                  @click="handlerDelete(i)">删除</a-button>
          </div>
            
        </a-form-model-item>
      </div>
    </a-form-model>
    <a-button v-if="advertList.length < MAX_COUNT"
              class="ml-14" type="primary"
              @click="handlerAdd">新增推荐位</a-button>
    <a-divider />
    <div style="text-align:center;">
      <!-- <a-button>取消</a-button> -->
      <a-button type="primary"
                @click="onSubmit"
                style="margin:0 10px;">保存</a-button>
    </div>
  </div>
</template>

<script>
import { getPCHomeAdvertList, editHomeAdvert } from "@/api/pc/advert"
import { requiredRule } from "@/hooks/use-form-rules"
import {
  getTypeList,
  getSubTypeList,
} from "@/api/pc/mall-sell.js"
export default {
  data () {
    return {
      requiredRule: requiredRule,
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      typeList: [],
      advertList: [],

      MAX_COUNT: 8,
    }
  },
  mounted () {
    this.initData()
    this.initTypeList()
  },
  methods: {
    async initData () {
      const { data, code } = await getPCHomeAdvertList()
      if (code === 0) {
        this.advertList = data.list.map(el=>{
          el.value = [Number(el.type_id),Number(el.son_type_id)]
          
          return el
        })
      }
    },

    async initTypeList () {
      const { data, code } = await getTypeList()
      if (code == 0) {
        this.typeList = data.list.map(el => {
          let obj = {
            label: el.type_name,
            value: el.type_id,
            isLeaf: false,
            loading: true,
          }
          this.loadData([obj])
          return obj
        })
      }
    },

    async loadData (selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];

      targetOption.loading = true;
      // load options lazily
      targetOption.loading = false;
      const { data } = await getSubTypeList({ parent_type_id: targetOption.value })

      targetOption.children = data.list.map(el => {
        return {
          label: el.type_name,
          value: el.type_id,
          isLeaf: true,
        }
      });
      this.typeList = [...this.typeList];
    },

    onSubmit () {
      let params = []
      console.log((this.advertList.length)%2)
      if((this.advertList.length)%2 != 0){
        this.$message.warning('推荐位只可以添加2个或4个')
        return
      }
      this.advertList.map((el, i) => {
        if (el.value.length > 0) {
          let obj = {
            id: 0,
            sort: i+1,
            type_id: el.value[0],
            son_type_id: el.value[1] || 0
          }
          params.push(obj)
        }
      })
      editHomeAdvert({ data: JSON.stringify(params) }).then((res) => {
        if (res.code === 0) {
          this.$message.success("保存成功！")
        }
      })
    },

    handlerDelete(index){
      this.advertList.splice(index, 1)
    },

    // 新增
    handlerAdd () {
      if (this.advertList.length < this.MAX_COUNT) {
        this.advertList.push({
          id: 0,
          sort: this.advertList.length+1,
          type_id: 0,
          son_type_id: 0,
          value: []
        })
        // 必须是偶数
        if(this.advertList.length % 2){
          this.advertList.push({
            id: 0,
            sort: this.advertList.length+1,
            type_id: 0,
            son_type_id: 0,
            value: []
          })
        }
      }
    },

    handlerRemove (index) {
      this.advertList.splice(index, 1)
    },
  },

}
</script>

<style lang="less">
.home-advert-layout {
  display: flex;
  padding-left: 20px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 40px;
  &.last {
    border-bottom: none;
    margin-bottom: 0;
  }
  .home-advert-right-layout {
    padding-bottom: 0px;
  }
}

.advert-title {
  position: absolute;
  top: 7px;
  left: 60px;
  font-weight: 600;
  font-size: 16px;
}
.border-b-1 {
  position: relative;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 0px;
}
</style>