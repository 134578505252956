<template>
  <div class="bg-white">
    <a-tabs type="card" :tabBarGutter="10">
      <a-tab-pane v-for="item in tabList" :key="item.id" :tab="item.name">
        <component :is="item.component"></component>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import {
  HomeAdvert,
  TopAdvert,
  BottomAdvert,
  PersonAdvert
} from "./adverts"
export default {
  data() {
    return {
      tabList: [
        {
          id: 1,
          name: "首页推荐位",
          component: HomeAdvert,
        },
        {
          id: 2,
          name: "顶部轮播图",
          component: TopAdvert,
        },
        {
          id: 3,
          name: "底部轮播图",
          component: BottomAdvert,
        },
        {
          id: 4,
          name: "个人中心推荐位",
          component: PersonAdvert
        }
      ],
    }
  },
  methods: {
    callback(key) {
      console.log(key)
    },
  },
}
</script>
<style>

.card-container {
  background: #f5f5f5;
  overflow: hidden;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
</style>